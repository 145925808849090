import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import { reduxForm } from 'redux-form';

import TextareaInput from '@common/components/form/inputs/textarea';
import TimePickerInput from '@common/components/form/inputs/time';
import {
  Row, Group, Label, DatePickerInput, CheckboxInput,
} from '@common/components/form';
import Carousel from '@common/components/carousel';
import * as alert from '@common/services/alert';
import ExchangeInfo from '../../components/exchange/info';
import SendList from '../../components/send-list';
import postExchange from '../../actions/post-exchange';
import { convertTimeToMoment } from '../../utils/exchange';
import { validate, warn } from './validate';
import { EExchangeTypes } from '../../definitions';

const transformShiftToValues = (shift) => ({
  shift_id: shift.id,
  date: moment(shift.date),
  start_time: moment(shift.start_time).format('HH:mm'),
  end_time: shift.end_time ? moment(shift.end_time).format('HH:mm') : null,
  no_end_time: !shift.end_time,
});

class AddExchangeFormDefault extends React.Component {
  static defaultProps = {
    formValues: {
      function_ids: [],
    },
  };

  constructor() {
    super();

    this.submitForm = this.submitForm.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { initialValues, date, shift } = this.props;

    if (shift) {
      this.props.initialize({ ...initialValues, ...transformShiftToValues(shift) });
    } else if (date) {
      this.props.initialize({ ...initialValues, date });
    }
  }

  componentDidUpdate() {
    const { formValues, change } = this.props;
    const { start_time: startTime, end_time: endTime } = formValues;
    if (startTime > endTime) {
      change('end_time', startTime);
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  static props;

  getPreviewObject() {
    const { loggedUser, formValues } = this.props;
    const { date, start_time: startTime, end_time: endTime, no_end_time: noEndTime } = formValues;

    return {
      date: moment(date),
      start_time: startTime ? convertTimeToMoment(startTime) : '',
      end_time: endTime && !noEndTime ? convertTimeToMoment(endTime) : '',
      user: loggedUser,
    };
  }

  async submitForm(values, dispatch) {
    const { onClose, t } = this.props;
    const data = { ...values };

    try {
      if (values.type === 'FUNCTION' && values.function_ids) data.values = data.function_ids;
      if (values.no_end_time) data.end_time = null;

      const { exchangeId } = await dispatch(postExchange(data));

      alert.success((
        <Trans
          i18nKey="flexchange:add_exchange_form_default_exchange_created"
          values={{ date: values.date.format('dddd D MMMM') }}
          components={[<b>a</b>]}
        />
      ));

      return onClose(exchangeId);
    } catch (response) {
      return alert.forStatus(response.status_code, {
        warning: t('flexchange:add_exchange_form_defaullt_warning_creating_exchange'),
        error: t('flexchange:add_exchange_form_defaullt_error_creating_exchange'),
      });
    }
  }

  render() {
    const {
      shift, network, loggedUser, functions, formValues, invalid, submitting, t,
    } = this.props;
    const { date, start_time: startTime } = formValues;

    const dateText = date ? date.format('dddd DD MMMM') : null;
    const preview = this.getPreviewObject();

    return (
      <Carousel
        footer={{
          onCancel: this.props.onPrevious || this.props.onClose,
          onSubmit: this.props.handleSubmit(this.submitForm),
          props: {
            invalid,
            submitting,
            submitText: t('flexchange:add_exchange_form_default_send'),
          },
        }}
        indicator={false}
      >
        {!shift ? (
          <Carousel.Card>
            <DatePickerInput
              inline
              name="date"
              minDate={moment().subtract(5, 'days')}
              calendarClassName="inline"
            />

            {date ? (
              <div className="Form__block">
                <div className="Form__block__container Form__block__container--padding-large">
                  <div className="AddExchangeForm__date">{dateText}</div>

                  <Row>
                    <Group>
                      <Label forInput="start_time" text={t('flexchange:add_exchange_form_default_start_time')} />
                      <TimePickerInput
                        minutesPerStep={5}
                        name="start_time"
                      />
                    </Group>
                    <Group>
                      <Label forInput="end_time" text={t('flexchange:add_exchange_form_default_end_time')} />
                      <TimePickerInput
                        minutesPerStep={5}
                        disabled={formValues.no_end_time}
                        name="end_time"
                        min={startTime}
                      />
                      <div style={{ marginTop: 8 }}>
                        <CheckboxInput
                          name="no_end_time"
                          label={t('flexchange:add_exchange_form_default_disable_end_time')}
                        />
                      </div>
                    </Group>
                  </Row>
                </div>
              </div>
            ) : null}
          </Carousel.Card>
        ) : undefined}
        <Carousel.Card>
          <div className="Form__block">
            <ExchangeInfo exchange={preview} loggedUser={loggedUser} />
            <TextareaInput
              name="description"
              className="Form__control Form__block__container"
              placeholder={t('flexchange:add_exchange_form_default_description_placeholder')}
            />
          </div>

          <SendList {...{ network, functions, formValues }} />
        </Carousel.Card>
      </Carousel>
    );
  }
}

export const initialValues = {
  date: moment(),
  start_time: '10:00',
  end_time: '15:00',
  no_end_time: false,
  description: '',
  type: EExchangeTypes.FUNCTION,
  function_ids: [],
};

export default withTranslation()(reduxForm({
  form: 'add-exchange',
  initialValues,
  validate,
  warn,
})(AddExchangeFormDefault));
