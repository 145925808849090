import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import * as R from 'ramda';
import moment from 'moment';
import ProfileImage from '@common/components/profile-image';
import TextareaInput from '@common/components/form/inputs/textarea';
import * as alert from '../../../../common/services/alert';
import Carousel from '../../../../common/components/carousel';
import PureList from '../../../../common/components/list';
import Spinner from '../../../../common/components/spinner';
import { Label } from '../../../../common/components/form';
import ExchangeInfo from '../../components/exchange/info';
import postExchange from '../../actions/post-exchange';
import * as userSelector from '../../../core/selectors/logged-user';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { EExchangeTypes } from '../../definitions';

class AddExchangeFormIntegrated extends React.Component {
  constructor() {
    super();

    this.state = {
      receivers: null,
    };

    this.mounted = true;

    this.onSubmit = this.onSubmit.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { shift } = this.props;

    if (shift) this.fetchReceivers(shift.id);
  }

  async UNSAFE_componentWillUpdate(nextProps) {
    const { shift } = this.props;

    if (!shift && nextProps.shift) {
      this.fetchReceivers(nextProps.shift.id);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;

    this.mounted = false;

    reset();
  }

  static props;
  mounted;

  async fetchReceivers(shiftId) {
    const { items } = await this.props.fetchReceivers(shiftId);

    if (this.mounted) this.setState({ receivers: items });
  }

  async onSubmit(values, dispatch) {
    const { receivers } = this.state;
    const { shift, onClose, t } = this.props;

    try {
      if (!shift) return alert.error(t('flexchange:add_exchange_form_integrated_error_no_shift'));

      const { exchangeId } = await dispatch(postExchange({
        type: EExchangeTypes.USER,
        ...R.pick(['date', 'start_time', 'end_time'], shift),
        shift_id: shift.id,
        user_ids: R.pluck('id', receivers),
        description: values.description,
      }));

      alert.success(
        <Trans
          i18nKey="flexchange:add_exchange_form_integrated_alert_success"
          values={{ date: moment(shift.date).format('dddd D MMMM') }}
          components={[<b>a</b>]}
        />,
      );

      onClose(exchangeId);

      return true;
    } catch (response) {
      console.log('debug error', response);
      return alert.forStatus(response.status_code, {
        warning: t('flexchange:add_exchange_form_integrated_warning_creating'),
        error: t('flexchange:add_exchange_form_integrated_error_creating'),
      });
    }
  }

  render() {
    const { receivers } = this.state;
    const {
      shift, loggedUser, handleSubmit, submitting, onClose, onPrevious, t,
    } = this.props;

    return (
      <div style={{ flex: 1 }}>
        <Carousel.Card>
          <div className="Form__block">
            <ExchangeInfo exchange={{ ...shift, user: loggedUser }} />

            <TextareaInput
              name="description"
              className="Form__control Form__block__container"
              placeholder={t('flexchange:add_exchange_form_integrated_description_placeholder')}
            />

            <div className="Form__block">
              <Label text={t('flexchange:add_exchange_form_integrated_send_to', { count: receivers ? receivers.length : 0 })} />
              <div className="Form__block__container Shift__Receivers">
                {Array.isArray(receivers) ? (
                  <PureList
                    items={receivers}
                    renderRow={ProfileImage}
                    rowProps={(item) => ({
                      user: item,
                      size: 30,
                    })}
                    placeholder={(
                      <small><Trans i18nKey="flexchange:add_exchange_form_integrated_receivers_placeholder" /></small>
                    )}
                  />
                ) : <Spinner size="small" centered />}
              </div>
            </div>
          </div>
        </Carousel.Card>
        <Carousel.Footer
          index={1}
          maxIndex={1}
          submitText={t('flexchange:add_exchange_form_default_send')}
          goPrevious={onPrevious}
          goNext={R.F}
          onCancel={onClose}
          onSubmit={handleSubmit(this.onSubmit)}
          submitting={submitting}
          invalid={receivers === null}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
});

const mapDispatchToProps = {
  fetchReceivers: require('../../actions/fetch-receivers').default,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'post-shift',
  initialValues: {
    description: '',
  },
})(AddExchangeFormIntegrated)));
