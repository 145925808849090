import * as React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import Profile from '../../../../common/components/profile/large';
import Text from '../../../../common/components/text';
import mergeNames from '../../../../common/utils/merged-names-text';

const createTitle = (exchange, t) => {
  if (exchange.title) return exchange.title;

  const startTime = moment(exchange.start_time).format('HH:mm');
  const endTime = exchange.end_time ? moment(exchange.end_time).format('HH:mm') : t('flexchange:exchange_info_no_end_time');

  return `${startTime} - ${endTime}`;
};

const ExchangeInfo = ({ loggedUser, exchange }) => {
  const { t } = useTranslation();

  const date = moment(exchange.date).format('dddd D MMMM');

  // $FlowFixMe
  const createdIn = !!exchange.created_in && exchange.created_for !== 'USER' && (
    <small className="Exchange__Info__placed-in">
      <Trans i18nKey="flexchange:exchange_info_send_to" values={{ names: mergeNames(exchange.created_in.values, 3, null, (name) => name).join('') }} />
    </small>
  );

  return (
    <div className="Exchange__Info">
      <div className="Exchange__Info__date">{date}</div>

      <Profile
        inverted
        size={45}
        user={exchange.user}
        className="Exchange__User"
        enablePopover={exchange.user && loggedUser && exchange.user.id !== loggedUser.id}
      >
        {/* $FlowFixMe */}
        <span className="Exchange__title">{createTitle(exchange, t)}</span>
      </Profile>

      {/* $FlowFixMe */}
      {typeof exchange.description === 'string' && (
        <div className="Exchange__Info__description">
          <Text>{exchange.description}</Text>
        </div>
      )}

      {createdIn}
    </div>
  );
};

export default ExchangeInfo;
