import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

import CheckboxValues from '@common/components/form/inputs/checkbox-values';
import CheckboxGroup from '@common/components/form/inputs/checkbox-group';
import { Label } from '@common/components/form';
import PureList from '@common/components/list';

import mergeNames from '@common/utils/merged-names-text';
import { EExchangeTypes } from '../../definitions';

const createLabel = (network, type, functions = [], t) => {
  if (type === EExchangeTypes.ALL) {
    return (
      <Label
        forInput="type"
        text={t('flexchange:send_list_send_to_everyone_in', { networkName: network.name })}
      />
    );
  } if (functions.length > 0) {
    return (
      <Label
        forInput="type"
        text={t('flexchange:send_list_send_to', { names: mergeNames(functions, 2, 'name', (name) => name).join('') })}
      />
    );
  }

  return <Label forInput="type" text={t('flexchange:send_list_select_receivers')} />;
};

const SendList = ({ network, functions, formValues: { type, function_ids: functionIds }, t }) => {
  const selectedFunctions = functionIds.map((id) => R.find(R.propEq('id', id), functions));
  const label = createLabel(network, type, selectedFunctions, t);

  return (
    <div className="Form__block">
      {label}

      <div className="Form__block__container">
        <CheckboxValues
          name="type"
          label={t('flexchange:send_list_all_network')}
          values={[EExchangeTypes.FUNCTION, EExchangeTypes.ALL]}
        />

        <PureList
          items={functions}
          renderRow={CheckboxGroup}
          rowProps={(item) => ({
            id: item.id,
            name: 'function_ids',
            disabled: type === EExchangeTypes.ALL,
            label: item.name,
          })}
        />
      </div>
    </div>
  );
};

SendList.propTypes = {
  network: PropTypes.object.isRequired,
  functions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  formValues: PropTypes.object,
};

export default withTranslation()(SendList);
