import React from 'react';
import { omit } from 'lodash';
import { BaseFieldProps, Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

import { Checkbox } from '../checkbox';
import type { CheckboxOwnProps } from '../checkbox';

type ReduxFormFieldProps = CheckboxOwnProps & {
  values: string[];
  input: Omit<WrappedFieldInputProps, 'onChange' | 'value'> & {
    value: string;
    onChange: (newValue: string) => void;
  }
};

/**
 * Checks on what position the provided value is inside the values Array.
 * If the position is 0 the value is false, if its 1 the value is true.
 * onChange will pass the value inside the values array, false is the value in position 0,
 * true is the value in position 1.
 */
const ReduxFormField = ({ values, input, meta, ...props }: Omit<WrappedFieldProps, 'input'> & ReduxFormFieldProps) => {
  return (
    <Checkbox
      error={meta.touched && meta.error}
      {...input}
      value={values[1] === input.value}
      onChange={(newValue) => {
        input.onChange(values[+newValue]);
      }}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

/**
 * @deprecated This component is only
 * here to support state management of legacy code, that should actually
 * be handled by the component implementing the form
 */
const ReduxFormCheckboxValues = ({ name, ...props }: BaseFieldProps & ReduxFormFieldProps) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default ReduxFormCheckboxValues;
