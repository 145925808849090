import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import Default from './default';
import Integrated from './integrated';
import Carousel from '../../../../common/components/carousel';
import AsyncList from '../../../../common/components/list/async';
import ShiftItem from './shift';

class AddExchangeForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      shift: props.shift || null,
    };

    this.handleSelectShift = (shift) => this.setState({ shift });
    this.handleUnsetShift = () => this.setState({ shift: null });
  }

  static props;

  render() {
    const { shift } = this.state;
    const {
      organisation, network, functions, loggedUser, onClose, formValues, date,
    } = this.props;

    if (!organisation.integration || organisation.integration.flexchange_type === 'default') {
      return (
        <form className="Form Form--horizontal Form--horizontal-filled">
          <Default {...{
            organisation, network, functions, loggedUser, onClose, formValues, date,
          }}
          />
        </form>
      );
    }

    if (!shift) {
      return (
        <Carousel.Card>
          <AsyncList
            containerClassName="Shifts"
            data={{
              onFetch: () => this.props.fetchShifts()
                .then((request) => ({
                  data: request.data.filter((item) => item.exchange_id === null),
                })),
            }}
            renderRow={ShiftItem}
            rowProps={{
              onClick: this.handleSelectShift,
            }}
            placeholder={(
              <center>
                <small><Trans i18nKey="flexchange:add_exchange_form_no_shifts_found" /></small>
              </center>
            )}
          />
        </Carousel.Card>
      );
    }

    return (
      <form className="Form Form--horizontal Form--horizontal-filled">
        <Integrated
          // $FlowFixMe
          {...{
            organisation, network, functions, loggedUser, onClose, formValues, date,
          }}
          shift={shift}
          onPrevious={this.handleUnsetShift}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formValues: (state.form['add-exchange'] || {}).values,
});

const mapDispatchToProps = {
  fetchShifts: require('../../../schedules/actions/fetch-shifts').call,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddExchangeForm));
