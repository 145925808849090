import { EExchangeTypes } from '../../definitions';

export const validate = (values) => {
  const errors = {};

  if (!values?.date) errors.date = true;
  if (!values?.start_time || (!values?.end_time && !values?.no_end_time)) errors.date = true;
  if (values?.start_time && values?.end_time && values.start_time > values.end_time) errors.end_time = true;
  if (values?.type === EExchangeTypes.FUNCTION && values?.function_ids.length === 0) {
    errors.type = true;
  }

  return errors;
};

export const warn = (values) => {
  const warnings = {};

  if (values?.start_time && values?.end_time && values.start_time === values.end_time) {
    warnings.end_time = true;
  }

  return warnings;
};
